/* eslint-disable max-classes-per-file */

class UnauthorizedException extends Error {
  constructor(data) {
    super(data);
    this.name = "UnauthorizedException";
  }
}

class OrderLockException extends Error {
  constructor(payload) {
    super(payload.data.message);
    this.name = "OrderLockException";
    this.status = payload.data.status;
  }
}

export { UnauthorizedException, OrderLockException };
