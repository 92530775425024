import { flatten } from "@/plugins/helpers";
import router from "@/router";
import AccountApi from "@/services/account.api";
import AuthApi from "@/services/auth.api";
import TrainplanetApi from "@/services/trainplanet.api";
import { OrderLockException } from "@/services/trainplanet.exceptions";
import moment from "moment";
import vueCookie from "vue-cookie";

const vuex = {};

vuex.state = {
  loading: false,
  error: null,
  errorCode: null,
  fetchOrder: null,
  orderId: null,
  tempMail: "",
  token: null,
  offerMode: false,
  data: {
    customer: null,
    tenant: null,
  },
  itemId: null,
  paymentSession: null,
  isOrderLock: false,
  orderLocks: JSON.parse(localStorage.getItem("orderLocks")) || {},
  orderLockSchedule: null,
};

vuex.getters = {
  offerMode: (state) => state.offerMode,
  loading: (state) => state.loading,
  tempMail: (state) => state.tempMail,
  error: (state) => state.error,
  errorCode: (state) => state.errorCode,
  api: (state) => new TrainplanetApi(state.orderId, state.token),
  authApi: (state) => new AuthApi(state.orderId, state.token),
  accountApi: (state) => new AccountApi(state.orderId, state.token),
  data: (state) => state.data,
  orderId: (state) => state.orderId,
  itemId: (state) => state.itemId,
  orderLockExpiry: (state) => state.orderLocks[state.orderId],
  paymentSession: (state) => state.paymentSession,
  isCustomerValid: (state) => {
    let { customer } = state.data;

    // Non required values
    delete customer.billing;
    delete customer.delivery.address2;
    delete customer.delivery.region;

    // Validate
    customer = flatten(customer);

    return !Object.values(customer).some((x) => x == null || x === "");
  },
};

vuex.mutations = {
  setOfferMode: (state, value) => {
    state.offerMode = value;
  },
  setTempMail: (state, value) => {
    state.tempMail = value;
  },
  setLoading: (state, value) => {
    state.loading = value;
  },
  setError: (state, error) => {
    state.error = error;
  },
  setErrorCode: (state, errorCode) => {
    state.errorCode = errorCode;
  },
  setOrderId: (state, orderId) => {
    state.orderId = orderId;
  },

  setToken: (state, token) => {
    state.token = token;
  },
  setOrder: (state, order) => {
    state.data = order;
  },

  setPaymentSession: (state, session) => {
    state.paymentSession = session;
  },
  orderLocked: (state, expires) => {
    state.orderLocks[state.orderId] = expires;
    localStorage.setItem("orderLocks", JSON.stringify(state.orderLocks));
  },
  orderUnlocked: (state) => {
    delete state.orderLocks[state.orderId];
    localStorage.setItem("orderLocks", JSON.stringify(state.orderLocks));
  },
  lockOrder: (state) => {
    state.isOrderLock = true;
  },
  unlockOrder: (state) => {
    state.isOrderLock = false;
  },
};

vuex.actions = {
  async addGiftCards({ getters }, { orderId, payload }) {
    return await getters.api.addGiftcards(orderId, payload);
  },

  async confirmationCheck({ getters }, pushUrl) {
    return await getters.api.confirmationCheck(pushUrl);
  },

  commitBlockingError({ commit }, error) {
    commit("setError", error);
    return error;
  },

  async fetchOrder({ commit, getters }) {
    const order = await getters.api.getOrder(getters.orderId);
    commit("setOrder", order);
    return order;
  },

  async getOrderConfirmation({ getters }) {
    return getters.api.getConfirmationHTML(getters.orderId);
  },

  async fetchPaymentSession(
    { commit, getters },
    { system, checkoutUrl, confirmationUrl }
  ) {
    const session = await getters.api.getPaymentSession(
      system,
      getters.orderId,
      checkoutUrl,
      confirmationUrl
    );
    commit("setPaymentSession", session);
    return session;
  },

  async validatePayment({ getters }) {
    return await getters.api.validatePayment(getters.orderId);
  },

  async updateOrderDistributions({ commit, getters, state }) {
    state.loading = true;
    const order = await getters.api.updateOrder(getters.orderId, {
      distributions: getters.data.distributions,
    });
    commit("setOrder", order);
    state.loading = false;
    return order;
  },

  async getRefundAmount({ getters, state }, params) {
    return await getters.api.getRefundAmount(params);
  },

  async cancelAndRefund({ getters, state }, itemId, externalReferences) {
    state.itemId = itemId;
    state.externalReferences = externalReferences;
    await getters.api.cancelAndRefund(
      getters.itemId,
      getters.externalReferences
    );
  },

  async removeItem({ getters, state }, payload) {
    state.itemId = payload.itemIds[0];
    state.orderId = payload.orderId;
    return await getters.api.removeItem(getters.orderId, getters.itemId);
  },

  async cancelOrder({ getters, state }, itemId, externalId) {
    state.itemId = itemId;
    state.externalId = externalId;
    await getters.api.cancelOrder(getters.itemId, getters.externalId);
  },

  async resendTicket({ getters, state }, itemId) {
    state.itemId = itemId;
    return await getters.api.resendTicket(itemId);
  },
  async getFiles({ getters, state }, itemId) {
    state.itemId = itemId;
    return await getters.api.getFiles(itemId);
  },

  async validateTicket({ getters, state }, itemId) {
    state.itemId = itemId;
    return await getters.api.validateTicket(itemId);
  },

  async lockOrder({ commit, getters, state }, { reason, orderId }) {
    let orderLockExpiry = vueCookie.get("_order_expiry_");
    const lastOrderId = vueCookie.get("_order_id_");

    if (
      !orderLockExpiry || // if we don't have any expiry date
      moment(orderLockExpiry).isSameOrBefore(moment()) || // if expired
      lastOrderId !== orderId // if order ids don't match
    ) {
      try {
        const locks = await getters.api.lockOrder(getters.orderId, reason);

        orderLockExpiry = locks.reduce((a, b) => (a.expires < b.expires ? a.expires : b.expires), 0); // prettier-ignore
        orderLockExpiry = moment.utc(orderLockExpiry).local().format("YYYY-MM-DD HH:mm:ss"); // prettier-ignore

        vueCookie.set("_order_expiry_", orderLockExpiry);
        vueCookie.set("_order_id_", orderId);

        commit("lockOrder");
      } catch (error) {
        if (error instanceof OrderLockException) {
          localStorage.setItem("paymentURL", window.location.href);
          return router.push({ name: "LockedPage", params: { locale: "en" } });
        }

        throw error;
      }
    }

    // schedule the renewal of the order lock
    if (orderLockExpiry) {
      const expiryInMilliseconds = moment(orderLockExpiry).diff(moment());

      clearTimeout(state.orderLockSchedule);
      state.orderLockSchedule = setTimeout(() => {
        this.dispatch("order/lockOrder", {
          reason:
            "Order lock has been extended from the customer/payment page.",
          orderId: "",
        });
      }, expiryInMilliseconds);
    }
  },

  checkOrderExpire({}, callback) {
    let timer;
    clearTimeout(timer);
    const afterSeconds = moment().millisecond() + 600000;
    timer = setTimeout(() => {
      callback();
    }, afterSeconds);
  },

  async unlockOrder({ commit, getters, state }) {
    await getters.api.unlockOrder(getters.orderId);
    vueCookie.delete("_order_expiry_");
    clearTimeout(state.orderLockSchedule);
    commit("orderUnlocked");
  },

  async fetchDistributionOptions({ getters }) {
    return await getters.api.listDistributionOptions(getters.orderId);
  },
};

export default {
  namespaced: true,
  state: vuex.state,
  getters: vuex.getters,
  mutations: vuex.mutations,
  actions: vuex.actions,
};
