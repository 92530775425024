import i18n from "@/plugins/i18n";
import { useRouter } from "vue-router";
import { bracketHandler } from "@/utils/helper.js";

const axios = require("axios");
const router = useRouter();

export default class AccountApi {
  constructor(tenantId) {
    this.client = axios.create({
      baseURL: `${process.env.VUE_APP_TRAINPLANET_API_URL_BASE}`,
    });
    this.client.interceptors.request.use((request) => {
      request.headers["Accept-Language"] = i18n.global.locale;
      request.headers["tenantId"] = tenantId;
      request.headers["Content-Type"] = "application/json";
      request.headers["Authorization"] =
        "Bearer " + localStorage.getItem("auth_token");

      return request;
    });

    this.client.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        const error = err.response;
        if (
          (error.status == 401 ||
            (error.status == 404 &&
              err.response.config.url == "account/jwt/refreshToken")) &&
          sessionStorage.getItem("route") != "Login" &&
          sessionStorage.getItem("route") != "pay" &&
          sessionStorage.getItem("route") != "OrderDetails" &&
          sessionStorage.getItem("route") != "ResetPasswordLink" &&
          sessionStorage.getItem("route") != "SendVerify" &&
          sessionStorage.getItem("route") != "Verify" &&
          sessionStorage.getItem("route") != "Register"
        ) {
          if (
            err.response.config.url == "account/jwt/refreshToken" &&
            error.status == 401
          ) {
            localStorage.removeItem("auth_token");
            localStorage.removeItem("refresh_token");
            window.location.href = "/sv?controlled=true";
          } else {
            if (localStorage.getItem("refresh_token")) {
              this.refreshToken({
                refreshToken: localStorage.getItem("refresh_token"),
              });

              setTimeout(() => {
                window.location.reload();
              }, 1000);
            } else {
              localStorage.clear();
              var url = new URL(window.location.href);
              var payUrl = process.env.VUE_APP_TRAINPLANET_PAY_URL;

              window.location.href =
                bracketHandler(payUrl) + "/sv?logout=true&came=" + url.origin;
            }
          }
        }
        return Promise.reject(error);
      }
    );
  }

  getAccount() {
    return this.client
      .get(`account/jwt`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (401 == error.response.data.status) {
          this.refreshToken({
            refreshToken: localStorage.getItem("refresh_token"),
          });
        }
      });
  }

  deleteAccount() {
    return this.client
      .delete(`account/jwt`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (401 == error.response.data.status) {
          this.refreshToken({
            refreshToken: localStorage.getItem("refresh_token"),
          });
        }
      });
  }

  refreshToken(data) {
    return this.client
      .post(`account/jwt/refreshToken`, data)
      .then((response) => {
        if (localStorage.getItem("refresh_token") && response) {
          localStorage.setItem("auth_token", response.data.accessToken);
        }
      })
      .catch((error) => {
        var url = new URL(window.location.href);
        var payUrl = process.env.VUE_APP_TRAINPLANET_PAY_URL;

        setTimeout(() => {
          if (localStorage.getItem("auth_token")) {
            localStorage.removeItem("auth_token");
            localStorage.removeItem("refresh_token");

            window.location.href =
              bracketHandler(payUrl) + "/sv?logout=true&came=" + url.origin;
          }

          if (
            404 == error.data.status &&
            sessionStorage.getItem("route") != "Login" &&
            sessionStorage.getItem("route") != "pay" &&
            sessionStorage.getItem("route") != "OrderDetails" &&
            sessionStorage.getItem("route") != "ResetPasswordLink" &&
            sessionStorage.getItem("route") != "SendVerify" &&
            sessionStorage.getItem("route") != "Verify" &&
            sessionStorage.getItem("route") != "Register"
          ) {
            localStorage.clear();
            window.location.href =
              bracketHandler(payUrl) + "/sv?logout=true&came=" + url.origin;
          }
          if (
            401 == error.data.status &&
            sessionStorage.getItem("route") != "Login" &&
            sessionStorage.getItem("route") != "pay" &&
            sessionStorage.getItem("route") != "OrderDetails" &&
            sessionStorage.getItem("route") != "ResetPasswordLink" &&
            sessionStorage.getItem("route") != "SendVerify" &&
            sessionStorage.getItem("route") != "Verify" &&
            sessionStorage.getItem("route") != "Register"
          ) {
            localStorage.clear();
            window.location.href =
              bracketHandler(payUrl) + "/sv?logout=true&came=" + url.origin;
          }
        }, 1000);
      });
  }

  registerPassenger(data) {
    return this.client.post(`account/jwt/passenger?force=true`, data);
  }

  updatePassenger(id, data) {
    return this.client.patch(`account/jwt/passenger/${id}`, data);
  }

  updateAccount(data) {
    return this.client.patch(`account/jwt/update`, data);
  }

  async getOrders(filter) {
    return await this.client.get("account/jwt/orders", {
      params: {
        ...filter,
      },
    });
  }

  getPassenger(id) {
    return this.client.get(`account/jwt/passenger?passengerId=${id}`);
  }

  getPassengerList(params) {
    return this.client.get(`account/jwt/passenger/list`, { params: params });
  }

  deletePassenger(id) {
    return this.client.delete(`account/jwt/passenger/${id}`);
  }
}
