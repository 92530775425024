export function makeCopy(object) {
  return JSON.parse(JSON.stringify(object));
}

export function bracketHandler(str, lang) {
  if (!str.startsWith("https://")) {
    str = "https://" + str;
  }

  const locale = sessionStorage.getItem("locale");
  const hasTrailingSlash = str.endsWith("/");

  if (hasTrailingSlash) {
    str = str.substring(0, str.length - 1);
  }

  if (lang) {
    return `${str}/${locale}`;
  } else {
    return str;
  }
}


export function decodeToken(str) {
  return str
    .replaceAll('1esacd1249', '|')
    .replaceAll('141udasm', '[')
    .replaceAll('dac1cas19', '^')
    .replaceAll('dase1318msq', ']')
    .replaceAll('12415cxad1', ')')
    .replaceAll('Por2casLdcas21', '(')
    .replaceAll('xMl3Jk', '+')
    .replaceAll('Por21Ld', '/')
    .replaceAll('Ml32', '=')
    .replaceAll('dasfaqwe31', '*')
    .replaceAll('dav12cs9', '?')
    .replaceAll('dasvasx124', '$');
}


export function encodeToken(str) {
  return str
    .replaceAll( '|','1esacd1249')
    .replaceAll( '[','141udasm')
    .replaceAll('^','dac1cas19')
    .replaceAll(']','dase1318msq')
    .replaceAll( ')','12415cxad1')
    .replaceAll( '(','Por2casLdcas21')
    .replaceAll( '+','xMl3Jk')
    .replaceAll( '/','Por21Ld')
    .replaceAll( '=','Ml32')
    .replaceAll( '*','dasfaqwe31')
    .replaceAll( '?','dav12cs9')
    .replaceAll('$','dasvasx124');
}
