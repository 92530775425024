function debounce(fn, delay) {
  let timeoutID = null;
  return function (...args) {
    clearTimeout(timeoutID);
    const that = this;
    timeoutID = setTimeout(() => {
      fn.apply(that, args);
    }, delay);
  };
}

function durationToString(duration) {
  // https://github.com/moment/moment/issues/463
  const response = [];

  if (duration.days() > 0) {
    response.push(`${duration.days()}d`);
  }

  if (duration.hours() > 0) {
    response.push(`${duration.hours()}h`);
  }

  if (duration.minutes() > 0) {
    response.push(`${duration.minutes()}min`);
  }

  return response.join(" ");
}

function mergeDeep(...objects) {
  const isObject = (obj) => obj && typeof obj === "object";

  return objects.reduce((prev, obj) => {
    const data = prev;

    Object.keys(obj).forEach((key) => {
      const pVal = data[key];
      const oVal = obj[key];

      if (Array.isArray(pVal) && Array.isArray(oVal)) {
        data[key] = pVal.concat(...oVal);
      } else if (isObject(pVal) && isObject(oVal)) {
        data[key] = mergeDeep(pVal, oVal);
      } else {
        data[key] = oVal;
      }
    });

    return data;
  }, {});
}

function flatten(obj) {
  let data = { ...obj };

  /* for (const i in data) {
    if (data[i] instanceof Object) {
      data = Object.assign(data, data[i]);
      delete data[i];
    }
  } */

  Object.keys(data).forEach((key) => {
    if (data[key] instanceof Object) {
      data = Object.assign(data, data[key]);
      delete data[key];
    }
  });

  return data;
}

export { debounce, durationToString, mergeDeep, flatten };
