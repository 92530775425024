import store from "@/store";
import { createRouter, createWebHistory } from "vue-router";
import i18n from "../plugins/i18n";

const routes = [
  {
    path: "/:locale",
    component: () => import("@/views/IndexRouter.vue"),
    children: [
      {
        path: "/:locale",
        name: "Login",
        props: true,
        component: () => import("@/views/login/Login.vue"),
      },
      {
        path: "/:locale/register",
        name: "Register",
        props: true,
        component: () => import("@/views/register/Register.vue"),
      },
      {
        path: "/:locale/my-orders",
        name: "OrderList",
        props: true,
        component: () => import("@/views/order-list/OrderList.vue"),
      },
      {
        path: "/:locale/verify/:verifyCode",
        name: "Verify",
        props: true,
        component: () => import("@/views/verify/Verify.vue"),
      },
      {
        path: "/:locale/verified/:verifyCode",
        name: "Verified",
        props: true,
        component: () => import("@/views/verify/Verify.vue"),
      },
      {
        path: "/:locale/passenger-list",
        name: "ListPassanger",
        props: true,
        component: () => import("@/views/passenger/PassengerList.vue"),
      },
      {
        path: "/:locale/auth-modal",
        name: "AuthModel",
        props: true,
        component: () => import("@/views/login-popup/login-popup.vue"),
      },
      {
        path: "/:locale/account-settings",
        name: "AccountSettings",
        props: true,
        component: () =>
          import("@/views/account-settings/Account-Settings.vue"),
      },
      {
        path: "/:locale/passanger-add",
        name: "AddPassenger",
        props: true,
        component: () => import("@/views/passenger/PassengerAddOrUpdate.vue"),
      },
      {
        path: "/:locale/passanger-update/:id",
        name: "UpdatePassenger",
        props: true,
        component: () => import("@/views/passenger/PassengerAddOrUpdate.vue"),
      },
      {
        path: "/:locale/resetPassword/:verifyCode",
        name: "ResetPassword",
        props: true,
        component: () => import("@/views/ResetPassword/ResetPassword.vue"),
      },

      {
        path: "/token",
        name: "Token",
        props: true,
        component: () => import("@/views/ResetPassword/ResetPassword.vue"),
      },

      {
        path: "/:locale/auth",
        name: "Auth",
        props: true,
        component: () => import("@/views/auth/AuthLogin.vue"),
      },
      {
        path: "/:locale/resetPasswordLink/",
        name: "ResetPasswordLink",
        props: true,
        component: () => import("@/views/ResetPassword/SendPasswordLink.vue"),
      },
      {
        path: "/:locale/resend-verify-code",
        name: "SendVerify",
        props: true,
        component: () => import("@/views/verify/SendVerify.vue"),
      },
      {
        path: "/:locale/locked",
        name: "LockedPage",
        props: true,
        component: () => import("@/views/LockedPage.vue"),
      },
      {
        path: "/:locale/cancel-order",
        name: "CancelOrder",
        props: true,
        component: () => import("@/views/cancel/CancelOrder.vue"),
      },
      {
        path: "/:locale/order-details",
        name: "OrderDetails",
        props: true,
        component: () => import("@/views/details/MyDetails.vue"),
      },
      {
        path: "/:locale/:orderId/:token",
        name: "pay",
        props: true,
        component: () => import("../views/Checkout/Index.vue"),
      },
      {
        path: "/:locale/:orderId/:token/confirmation",
        name: "confirmation",
        props: true,
        component: () => import("../views/Confirmation/Index.vue"),
      },
      {
        path: "/:locale/:pathMatch(.*)*",
        redirect: { name: "Login" },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    name: "ErrorNotFound",
    redirect: { name: "Login", params: { locale: "sv" } },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (!store.state.tenant.theme) {
    await store.dispatch("setTenant");
  }

  let { locale } = to.params;

  if (locale) {
    const localeParameter = locale.toLowerCase();
    let detectedLocale;

    for (const tenantLanguage of store.state.tenant.theme.languages) {
      // "en" => "en-SE"
      // "no" => "nb-NO"
      if (
        tenantLanguage.toLowerCase().startsWith(localeParameter) ||
        tenantLanguage.toLowerCase().endsWith(localeParameter)
      ) {
        detectedLocale = tenantLanguage;
        break;
      }
    }

    if (!detectedLocale) {
      detectedLocale = store.state.tenant.theme.languages[0];
    }

    if (i18n.global.locale !== detectedLocale) {
      i18n.global.locale = detectedLocale;
    }
  }

  next();
});

export default router;
