<template>
  <div class="container py-10" :class="{ 'padding-top-60': isMobile }">
    <router-view v-if="$store.state.tenant" :key="$route.fullPath"></router-view>
  </div>
  <!-- <maja-bottom-bar class="maja-payment" search="http://172.20.10.11:2347/en" activetab="cart" v-if="isMobile"
    style="position: fixed; bottom: 0; width: 100%"></maja-bottom-bar> -->

  <div v-if="$store.state.order.loading"
    class="uk-overlay uk-overlay-default fixed t.op-0 left-0 right-0 bottom-0 uk-flex uk-flex-center uk-flex-middle uk-flex-column"
    style="height: 100%;">
    <div uk-spinner="ratio: 2"></div>
    <h5>{{ $t("MODAL.loadingMessage") }}</h5>
  </div>
</template>

<style>
.padding-top-60 {
  padding-top: 60px !important;
}

.maja-bottom-bar .item-wrapper .bar-item a span {
  font-size: 12px;
}

.maja-bottom-bar .item-wrapper .bar-item.selected a span {
  color: var(--secondary-color);
}

.maja-bottom-bar .item-wrapper .bar-item.selected a {
  width: 65px;
}

.maja-bottom-bar .item-wrapper .bar-item a:hover {
  background-color: var(--secondary-color) !important;
  border-radius: 12px;
  color: white !important;
}

.maja-payment .maja-bottom-bar .item-wrapper .bar-item.selected a .basket-overview__journey-info-arrow.mat-icon svg path {
  fill: var(--secondary-color) !important;
}

.maja-bottom-bar .item-wrapper .bar-item a:hover .basket-overview__journey-info-arrow.mat-icon svg path {
  fill: var(--secondary-color) !important;
}

.maja-bottom-bar .item-wrapper .bar-item.selected a {
  color: var(--secondary-color) !important;
}

.maja-bottom-bar .item-wrapper .bar-item a:hover .basket-overview__journey-info-arrow.mat-icon svg path {
  fill: var(--secondary-color) !important;
}

.item-wrapper {
  gap: 0 !important;
}
</style>

<script>
import AccountApi from "@/services/account.api";
import { bracketHandler, decodeToken, encodeToken } from "@/utils/helper.js";
import createHost from "@midorina/cross-domain-storage/host.js";
import * as CryptoJS from 'crypto-js';
import queryString from 'query-string';
import TrainplanetApi from "./services/trainplanet.api";

export default {


  data: () => ({
    whitelist: [],
    isMobile: false,
    frame: null,
    activeRoute: ""
  }),
  computed: {


    AccountApiModel() {
      return new AccountApi(this.$store.state.tenant.id);
    },
    TrainPlanetModel() {
      if (this.$store.state.tenant.id) {
        return new TrainplanetApi(this.$store.state.tenant.id);

      }
    },
  },
  watch: {
    async $route(to, from) {

      if (from.name == "OrderDetails") {

        var tenant = await TrainplanetApi.getTenant();
        document.documentElement.setAttribute("theme", tenant.name.toLowerCase());
        document.title = tenant.name;
        const favicon = document.getElementById("favicon");
        favicon.href = tenant.theme.favIconUrl;
        window.location.reload()
      }
      if (localStorage.getItem("refresh_token")) {
        this.AccountApiModel.refreshToken({ refreshToken: localStorage.getItem("refresh_token") })
          .then(({ data }) => {
          })
          .catch((error) => {
            this.hasError = error.response.data.status;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    }
  },


  created: function () {
    var payUrl = process.env.VUE_APP_TRAINPLANET_PAY_URL

    var route = sessionStorage.getItem("route")
    var url = new URL(window.location.href);
    if (localStorage.getItem("refresh_token")) {
      this.AccountApiModel.refreshToken({ refreshToken: localStorage.getItem("refresh_token") })
        .then(({ data }) => {
        })
        .catch((error) => {
          this.hasError = error.response.data.status;
        })
        .finally(() => {
          this.loading = false;
        });
    } else if (route != "Login" &&
      route != "pay" &&
      route != "OrderDetails" &&
      route != "ResetPasswordLink" &&
      route != "SendVerify" &&
      route != "Verify" &&
      route != "Register" &&
      url.origin != bracketHandler(payUrl) &&
      !window.location.href.includes("booking_number") &&
      !window.location.href.includes("from")
    ) {

      setTimeout(() => {
        this.$router.push('')
      }, 1500)
    } else {

    }
    var storageHost = createHost([
      // MAIN PAGE
      {
        origin: "https://norrtag.se",
        allowedMethods: ['get', 'set', 'remove'],
      },
      {
        origin: "https://staging.norrtag.se",
        allowedMethods: ['get', 'set', 'remove'],
      },
      //DEV CUSTOMER

      {
        origin: "https://dev.customer.interrail.no",
        allowedMethods: ['get', 'set', 'remove'],
      },
      {
        origin: "https://dev.customer.interrail.se",
        allowedMethods: ['get', 'set', 'remove'],
      },

      {
        origin: "https://dev.customer.europarunt.se",
        allowedMethods: ['get', 'set', 'remove'],
      },
      {
        origin: "https://dev.customer.europarunt.no",
        allowedMethods: ['get', 'set', 'remove'],
      },
      {
        origin: "https://dev.minasidor.tagbokningen.se",
        allowedMethods: ['get', 'set', 'remove'],
      },
      {
        origin: "https://dev.minasidor.oresundstag.trainplanet.com",
        allowedMethods: ['get', 'set', 'remove'],
      },

      {
        origin: "https://dev.minasidor.resrobot.trainplanet.com",
        allowedMethods: ['get', 'set', 'remove'],
      },
      {
        origin: 'https://dev.customer.trainplanet.com',
        allowedMethods: ['get', 'set', 'remove'],
      },
      {
        origin: "https://dev.minasidor.norrtag.se",
        allowedMethods: ["get", "set", "remove"],
      },

      //DEV TİCKETS

      {
        origin: "https://dev.boka.tagbokningen.se",
        allowedMethods: ["get", "set", "remove"],
      },

      {
        origin: "https://dev.boka.oresundstag.trainplanet.com",
        allowedMethods: ["get", "set", "remove"],
      },

      {
        origin: "https://dev.kopbiljett.resrobot.trainplanet.com",
        allowedMethods: ["get", "set", "remove"],
      },

      {
        origin: "https://dev.shop.interrail.se",
        allowedMethods: ["get", "set", "remove"],
      },

      {
        origin: "https://dev.shop.interrail.no",
        allowedMethods: ["get", "set", "remove"],
      },

      {
        origin: "https://dev.shop.europarunt.se",
        allowedMethods: ["get", "set", "remove"],
      },

      {
        origin: "https://dev.shop.europarundt.no",
        allowedMethods: ["get", "set", "remove"],
      },

      {
        origin: "https://dev.tickets.trainplanet.com",
        allowedMethods: ["get", "set", "remove"],
      },
      {
        origin: "https://dev.boka.norrtag.se",
        allowedMethods: ["get", "set", "remove"],
      },

      //CUSTOMER PAGES
      {
        origin: "https://minasidor.norrtag.se",
        allowedMethods: ["get", "set", "remove"],
      },

      {
        origin: "https://customer.interrail.no",
        allowedMethods: ['get', 'set', 'remove'],
      },
      {
        origin: "https://customer.interrail.se",
        allowedMethods: ['get', 'set', 'remove'],
      },

      {
        origin: "https://customer.europarunt.se",
        allowedMethods: ['get', 'set', 'remove'],
      },
      {
        origin: "https://customer.europarunt.no",
        allowedMethods: ['get', 'set', 'remove'],
      },
      {
        origin: "https://minasidor.tagbokningen.se",
        allowedMethods: ['get', 'set', 'remove'],
      },
      {
        origin: "https://minasidor.oresundstag.trainplanet.com",
        allowedMethods: ['get', 'set', 'remove'],
      },

      {
        origin: "https://minasidor.resrobot.trainplanet.com",
        allowedMethods: ['get', 'set', 'remove'],
      },


      {
        origin: 'https://customer.trainplanet.com',
        allowedMethods: ['get', 'set', 'remove'],
      },

      // shoping URL
      {
        origin: "https://boka.tagbokningen.se",
        allowedMethods: ["get", "set", "remove"],
      },

      {
        origin: "https://boka.oresundstag.se",
        allowedMethods: ["get", "set", "remove"],
      },
      {
        origin: "https://kopbiljett.resrobot.trainplanet.com",
        allowedMethods: ["get", "set", "remove"],
      },

      {
        origin: "https://shop.interrail.se",
        allowedMethods: ["get", "set", "remove"],
      },

      {
        origin: "https://shop.interrail.no",
        allowedMethods: ["get", "set", "remove"],
      },

      {
        origin: "https://shop.europarunt.se",
        allowedMethods: ["get", "set", "remove"],
      },

      {
        origin: "https://shop.europarundt.no",
        allowedMethods: ["get", "set", "remove"],
      },

      {
        origin: "https://tickets.trainplanet.com",
        allowedMethods: ["get", "set", "remove"],
      },
      {
        origin: "https://boka.norrtag.se",
        allowedMethods: ["get", "set", "remove"],
      },
    ]);

    this.isMobile = window.mobileCheck();
  },
  
  beforeCreate() {
    var payUrl = process.env.VUE_APP_TRAINPLANET_PAY_URL
    var url = new URL(window.location.href)
    var redirect = ""
    var redirectFrom = ""
    if (url.origin == bracketHandler(payUrl) || url.origin.includes("minasidor") || url.origin.includes("customer")) {
      var to = {
        query: queryString.parse(url.search)
      }
      if (to.query.came) {
        redirect = to.query.came
      }

      if (to.query.from) {
        redirectFrom = to.query.from
      }
      if (window.location.href.includes("logout")) {
        localStorage.removeItem('auth_token')
        localStorage.removeItem('refresh_token')
        window.location.href = redirect
      }
      else if (to.query.logtick == "true") {
        localStorage.clear()
        window.location.href = bracketHandler(payUrl) + "/sv?logout=true&came=" + encodeURIComponent(redirect)
      } else {
        var mainUrlPath = url.pathname.toLocaleLowerCase();
        if (mainUrlPath == "" || mainUrlPath == "/" || mainUrlPath == "/no" || mainUrlPath == "/en" || mainUrlPath == "/en-SE" || mainUrlPath == "/nb-NO" || mainUrlPath == "/sv-SE" || mainUrlPath == "/sv" || mainUrlPath.includes("my-orders")) {
          if (to.query.logtick == "true") {

            localStorage.removeItem('auth_token')
            localStorage.removeItem('refresh_token')
            window.location.href = bracketHandler(payUrl) + "/sv?logout=true&came=" + encodeURIComponent(redirect)
          }
          if (!window.location.href.includes("forControl") && !window.location.href.includes(bracketHandler(payUrl)) && !to.query.accesstoken && !localStorage.getItem('auth_token') && !window.location.href.includes("controlled=true") && !window.location.href.includes('logout')) {
            window.location.href = bracketHandler(payUrl) + "/?forControl=true&came=" + window.location.href
          }
          if (window.location.href.includes(bracketHandler(payUrl)) && to.query.accesstoken) {

            var deAccess = CryptoJS.AES.decrypt(
              decodeToken(to.query.accesstoken).substring(6),
              decodeToken(to.query.accesstoken).substring(0, 6)
            ).toString(CryptoJS.enc.Utf8);
            var deRefresh = CryptoJS.AES.decrypt(
              decodeToken(to.query.refreshtoken).substring(6),
              decodeToken(to.query.refreshtoken).substring(0, 6)
            ).toString(CryptoJS.enc.Utf8);

            localStorage.setItem('auth_token', JSON.parse(deAccess).myAuth)
            localStorage.setItem('refresh_token', JSON.parse(deRefresh).myRefresh)
            if (redirect.includes("controlled=true")) {
              window.location.href = redirect + "&accesstoken=" + to.query.accesstoken + "&refreshtoken=" + to.query.refreshtoken
            } else {

              var subUrl = new URL(redirect)
              var subTo = {
                query: queryString.parse(subUrl.search)
              }

              if (Object.keys(subTo.query).length != 0) {
                window.location.href = redirect + "&accesstoken=" + to.query.accesstoken + "&refreshtoken=" + to.query.refreshtoken
              } else {
                window.location.href = redirect + "?accesstoken=" + to.query.accesstoken + "&refreshtoken=" + to.query.refreshtoken
              }

            }
          }
          if (!window.location.href.includes(bracketHandler(payUrl)) && to.query.accesstoken) {

            var deAccess = CryptoJS.AES.decrypt(
              decodeToken(to.query.accesstoken).substring(6),
              decodeToken(to.query.accesstoken).substring(0, 6)
            ).toString(CryptoJS.enc.Utf8);
            var deRefresh = CryptoJS.AES.decrypt(
              decodeToken(to.query.refreshtoken).substring(6),
              decodeToken(to.query.refreshtoken).substring(0, 6)
            ).toString(CryptoJS.enc.Utf8);
            localStorage.setItem('auth_token', JSON.parse(deAccess).myAuth)
            localStorage.setItem('refresh_token', JSON.parse(deRefresh).myRefresh)
          }
          if (window.location.href.includes("forControl")) {

            if (localStorage.getItem('auth_token')) {
              var keyAccess = makeid(6);
              var keyRefresh = makeid(6);
              var myAuth = localStorage.getItem('auth_token');
              var myRefresh = localStorage.getItem('refresh_token');
              var encrypted = CryptoJS.AES.encrypt(JSON.stringify({ myAuth }), keyAccess);
              var encryptedRefresh = CryptoJS.AES.encrypt(JSON.stringify({ myRefresh }), keyRefresh);
              var cryptedAccessToken = keyAccess + encodeToken(encrypted.toString())
              var cryptedRefreshToken = keyRefresh + encodeToken(encryptedRefresh.toString())
              var subUrl = new URL(redirect)
              var subTo = {
                query: queryString.parse(subUrl.search)
              }


              if (Object.keys(to.query).length > 3 && (
                window.navigator.userAgent.toLowerCase().includes('iphone') ||
                (window.navigator.userAgent.toLowerCase().includes('safari') &&
                  !window.navigator.userAgent.toLowerCase().includes('chrome'))
              )) {
                var url = ""

                if (Object.keys(subTo.query).length > 0) {
                  url = redirect + "&controlled=true" + "&accesstoken=" + cryptedAccessToken + "&refreshtoken=" + cryptedRefreshToken

                  window.location.href = url
                } else {
                  url = redirect + "?controlled=true" + "&accesstoken=" + cryptedAccessToken + "&refreshtoken=" + cryptedRefreshToken
                  window.location.href = url

                }

              } else {
                if (Object.keys(subTo.query).length > 0) {

                  window.location.href = redirect + "&controlled=true" + "&accesstoken=" + cryptedAccessToken + "&refreshtoken=" + cryptedRefreshToken
                } else {
                  window.location.href = redirect + "?controlled=true" + "&accesstoken=" + cryptedAccessToken + "&refreshtoken=" + cryptedRefreshToken

                }
              }
            } else {


              if (redirect.includes('from')) {
                if (redirect.includes("minasidor") || redirect.includes("customer")) {
                  var redirectURL = new URL(redirect)
                  var encodeStr = redirect.split('from=')[1]
                  window.location.href = redirectURL.origin + redirectURL.pathname + '?from=' + encodeURIComponent(new URL(encodeStr)) + '&controlled=true'

                } else {
                  window.location.href = redirect + '&controlled=true'
                }
              } else {
                if (Object.keys(to.query).length > 2) {
                  window.location.href = redirect + '&controlled=true'
                } else {
                  var subUrl = new URL(redirect)
                  var subTo = {
                    query: queryString.parse(subUrl.search)
                  }
                  if (Object.keys(subTo.query).length > 0) {
                    window.location.href = redirect + '&controlled=true'
                  } else {
                    window.location.href = redirect + '?controlled=true'
                  }
                }
              }
            }
          }
          if (window.location.href.includes("controlled=true")) {

            if (redirectFrom && localStorage.getItem('auth_token')) {
              window.location.href = redirectFrom
            }
          }
        }
      }
    }
  },
  methods: {
    makeid(length) {
      let result = '';
      const characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
      }
      return result;
    },
  },
};
window.mobileCheck = function () {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

function makeid(length) {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}
</script>
