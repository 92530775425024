import TrainplanetApi from "@/services/trainplanet.api";
import { createStore } from "vuex";
import order from "./modules/order";

function setTenantDocumentProperties(tenant) {
  const r = document.querySelector(':root');
  r.style.setProperty('--primary-color', tenant.theme.mainColor);
  r.style.setProperty('--secondary-color', tenant.theme.secondaryColor);
  r.style.setProperty('--header-color', tenant.theme.headerColor);
  r.style.setProperty('--header-title-color', tenant.theme.headerTitleColor);
  r.style.setProperty('--title-color', tenant.theme.titleColor);
  r.style.setProperty('--title-bg-color', tenant.theme.titleBackgroundColor);
  r.style.setProperty('--filter', tenant.theme.mainColorSvgFilters);
  r.style.setProperty('--filter-secondary', tenant.theme.secondaryColorSvgFilters);
  document.title = tenant.name;
  const favicon = document.getElementById("favicon");
  favicon.href = tenant.theme.favIconUrl;
}

export default createStore({
  state: {
    tenant: {},
    offerMode: false,
  },

  getters: {
    tenant: (state) => {
      // use order's tenant if we have one. else return what we have
      if (state.order.data.tenant && state.order.data.tenant !== state.tenant) {
        state.tenant = state.order.data.tenant;
        setTenantDocumentProperties(state.tenant);
      }

      return state.tenant;
    },
    offerMode: (state) => state.offerMode,
  },
  mutations: {
    setOfferMode(state, value) {
      state.offerMode = value;
    },
  },
  actions: {
    async setTenant({ state }) {
      state.tenant = await TrainplanetApi.getTenant();
      setTenantDocumentProperties(state.tenant);
    },
  },
  modules: {
    order,
  },
});
