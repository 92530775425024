import { createApp } from "vue";
import App from "./App.vue";
import i18n from "./plugins/i18n";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
//import "uikit/dist/js/uikit.min";
import "uikit/dist/js/uikit";
import "./assets/scss/_uikit.scss";
import "./assets/tailwind.css";
import { clickOutSide as vClickOutSide } from '@mahdikhashan/vue3-click-outside'

import moment from "moment";
import { durationToString } from "./plugins/helpers";

const app = createApp(App);

app.config.globalProperties.$filters = {
  time(dateTime) {
    return moment(dateTime).format("HH:mm");
  },
  date(dateTime) {
    return moment(dateTime).format("D MMM");
  },
  dateTimeString(dateTime) {
    return moment(dateTime).format("LL");
  },
  duration(ISO8601) {
    const duration = moment.duration(ISO8601);
    return durationToString(duration);
  },
  characterLimit(text, characterLength) {
    return (
      text.slice(0, characterLength) +
      (text.length > characterLength ? "..." : "")
    );
  },

  upperCase(value) {
    return value
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  },
};

app.directive('click-outside', vClickOutSide)

app.use(i18n).use(store).use(router).mount("#app");

export default app;
