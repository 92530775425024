import i18n from "@/plugins/i18n";

const axios = require("axios");

export default class AuthApi {
  constructor(tenantId) {
    this.client = axios.create({
      baseURL: `${process.env.VUE_APP_TRAINPLANET_API_URL_BASE}/account/jwt`,
    });
    this.client.interceptors.request.use((request) => {
      request.headers["Accept-Language"] = i18n.global.locale;
      request.headers["tenantId"] = tenantId;
      request.headers["Content-Type"] = "application/json";
      return request;
    });
  }

  register(data) {
    return this.client.post(`/create`, data).then((response) => {
      return response;
    });
  }
  verify(verifyId) {
    return this.client.post(`/verify/${verifyId}`);
  }

  resendVerifyLink(data) {
    return this.client.post(`/sendVerificationLink`, data).then((response) => {
      return response;
    });
  }

  resetPasswordLink(data) {
    return this.client.post(`/sendPasswordResetLink`, data).then((response) => {
      return response;
    });
  }

  resetPassword(data) {
    return this.client.post(`/resetPassword`, data).then((response) => {
      return response;
    });
  }

  auth(data) {
    return this.client.post(`/login`, data);
  }
}
